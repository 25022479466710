import React, { useContext } from 'react';
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import fetch from 'isomorphic-unfetch';
import { useSnackbar } from '../hooks/snackbar';
import getIsTestmode from './testmode';
import useModal from '../modals/use-modal';
import UpgradeDialogModal from '../modals/upgrade-dialog';
import APIError from './errors/api-error';

export const APIContext = React.createContext({ key: null });

export class IgnoreThisError extends Error {
  constructor() {
    // No UI popup if message is undefined
    super(undefined);
  }
}

async function handleFetchFailure(e) {
  if (e.message && e.message.toLowerCase().includes('not authorized')) {
    await signOut({
      callbackUrl: '/?error=SignedOut&r=401',
    });
    return;
  }

  // Typically user network has problem, but also means API unreachable
  if (e.message && e.message.includes('Failed to fetch')) {
    console.error('There was a network or CORS issue.', e);

    // Try fetch certs API, if doesnt work, must be offline
    try {
      await fetch('/api/contact', {
        method: 'GET',
      });
    } catch (checkError) {
      if (checkError.message.includes('Failed to fetch')) {
        // User is offline
        throw new Error('Network error, please check your connection and try again.');
      } else {
        Sentry.captureException(e);

        // API is offline/CORS issue
        throw new Error('Network error, please contact support if this continues.');
      }
    }
  }

  // Unknown fetch failure
  Sentry.captureException(e);
  throw e;
}

export function reloadSession() {
  const event = new Event('visibilitychange');
  document.dispatchEvent(event);
}

export default function useApi(forceProduction) {
  const apiCtx = useContext(APIContext);
  const { data: session, status } = useSession();
  const loading = status === 'loading';
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  function showUpgradeModal(message, title, additionalProperties = {}) {
    showModal(UpgradeDialogModal, {
      ...additionalProperties,
      text: message,
      title,
    });
  }

  async function apiRequest(method, route, data, apiKeyI, keyregenCount = 0) {
    if (!session && !loading) {
      await signOut({
        callbackUrl: '/?error=SignedOut',
      });
      return null;
    }

    const isCurrentTestmode = getIsTestmode();
    const isTestmodeRequest = forceProduction === undefined ? isCurrentTestmode : !forceProduction;
    let key = apiKeyI === undefined ? apiCtx.apiKey : apiKeyI;
    if (!key || isCurrentTestmode !== isTestmodeRequest) {
      try {
        key = await getApiKey(isTestmodeRequest);
      } catch (e) {
        try {
          await handleFetchFailure(e);
        } catch (er) {
          // Unknown network failure, user or DB is offline
          await Router.push('/_offline?ctx=getApiKey');
          return null;
        }
      }

      // No key returned but no error means user requires onboarding
      if (!key) {
        await Router.push('/onboarding?source=nokey');
        return null;
      }
    }

    let resultJSON;
    const apiUrl = isTestmodeRequest
      ? process.env.NEXT_PUBLIC_API_URL_TESTMODE
      : process.env.NEXT_PUBLIC_API_URL;

    try {
      const result = await fetch(`${apiUrl}/${route}`, {
        method,
        body: data ? JSON.stringify(data) : undefined,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'DOCK-API-TOKEN': key,
        },
      });

      try {
        resultJSON = await result.json();
      } catch (e) {
        return null; // some responses dont return json
      }
    } catch (e) {
      await handleFetchFailure(e);
    }

    if (!resultJSON) {
      const text = `There was a network error for: ${method} ${route}`;
      Sentry.captureException(new Error(text));
      enqueueSnackbar(
        {
          title: 'Network Error',
          text,
        },
        {
          variant: 'error',
        }
      );
      return null;
    }

    // If unauthorized, try regenerate the key
    if (resultJSON.status === 401) {
      // Check if the account was suspended, if so show error and stop
      if (resultJSON.message && resultJSON.message.indexOf('suspended') > -1) {
        await Router.push('/plans?source=suspended');
        return null;
      }

      // We tried too many times to regenerate a key and it failed, log user out
      if (keyregenCount > 2) {
        // Inform the user of the error
        enqueueSnackbar(
          {
            title: 'Unauthorized',
            text: 'Please try signing in again',
          },
          {
            variant: 'error',
          }
        );
        await signOut({
          callbackUrl: '/?error=SignedOut&reason=nokey',
        });
        return null;
      }

      // Try request another key then perform api request again
      return apiRequest(method, route, data, null, keyregenCount + 1);
    }

    // Check for JSON response error code
    if (resultJSON.status >= 400) {
      throw new APIError(
        resultJSON.error || resultJSON.message || resultJSON.type,
        resultJSON.status,
        {
          method,
          route,
          data,
        }
      );
    }
    return resultJSON;
  }

  async function apiGet(route) {
    return apiRequest('GET', route);
  }

  async function apiPost(route, data) {
    return apiRequest('POST', route, data);
  }

  async function apiPatch(route, data) {
    return apiRequest('PATCH', route, data);
  }

  async function apiDelete(route) {
    return apiRequest('DELETE', route);
  }

  async function getApiKey(isTestmode) {
    if (!session && !loading) {
      await signOut({
        callbackUrl: '/?error=SignedOut&v=2',
      });
      return null;
    }

    let result;
    try {
      result = await fetch(`/api/temporary-key?t=${isTestmode ? 1 : 0}`);
    } catch (e) {
      await handleFetchFailure(e);
    }

    const json = await result.json();

    if (!result.ok) {
      throw new Error(json.error);
    }

    if (json.key) {
      apiCtx.setAPIKey(json.key);
      return json.key;
    }

    return undefined;
  }

  function handleApiError(error, title) {
    console.error('API error:', error);

    // Check if error relates to plan upgrade
    if (error.status === 402) {
      // Only show upgrade modal if no plan, otherwise we have upgrade prompt snackbar
      const hasSubscription =
        session && session.user && session.user.subscription && session.user.subscription.id;
      if (!hasSubscription) {
        showUpgradeModal(title || error.message);
      } else {
        showUpgradeModal(error.message, 'Upgrade your plan to continue');
      }

      // We throw an ignore error to not show UI to user
      // but we should not proceed with intended UX
      throw new IgnoreThisError();
    } else {
      // Only send unexpected errors to sentry (not 400, 404, etc)
      if (error.status > 404) {
        Sentry.captureException(error);
      }

      // Inform the user of the error
      enqueueSnackbar(title || error.message, {
        variant: 'error',
      });
    }

    throw error;
  }

  return {
    showUpgradeModal,
    apiGet: async (route, sessionREMOVEME, title, shouldIgnoreHandleApiErrorFn = () => false) => {
      try {
        const result = await apiGet(route, {
          ...session,
        });
        return result;
      } catch (error) {
        if (shouldIgnoreHandleApiErrorFn(error)) {
          return null;
        }

        handleApiError(error, title);
      }
      return null;
    },
    apiPost: async (route, sessionREMOVEME, data, title, updateSession = true) => {
      try {
        const result = await apiPost(route, data, {
          ...session,
        });
        if (updateSession) {
          reloadSession();
        }
        return result;
      } catch (error) {
        handleApiError(error, title);
      }
      return null;
    },
    apiPatch: async (route, sessionREMOVEME, data, title, updateSession = true) => {
      try {
        const result = await apiPatch(route, data, {
          ...session,
        });
        if (updateSession) {
          reloadSession();
        }
        return result;
      } catch (error) {
        handleApiError(error, title);
      }
      return null;
    },
    apiDelete: async (route, sessionREMOVEME, title, updateSession = true) => {
      try {
        const result = await apiDelete(route, {
          ...session,
        });
        if (updateSession) {
          reloadSession();
        }
        return result;
      } catch (error) {
        handleApiError(error, title);
      }
      return null;
    },
  };
}
