import React, { useState } from 'react';
import { useRouter } from 'next/router';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMicOutlined';
import useTranslation from 'next-translate/useTranslation';

import { Chip } from '@mui/material';
import HomeIcon from '../../public/icons/nav/dashboard.svg';
import DIDIcon from '../../public/icons/nav/dids.svg';
import ActivityIcon from '../../public/icons/nav/activity.svg';
import ContactIcon from '../../public/icons/nav/contact.svg';
import CredentialsIcon from '../../public/icons/nav/credentials.svg';
import DeveloperIcon from '../../public/icons/nav/developer.svg';
import PlanIcon from '../../public/icons/nav/plan.svg';
import HelpIcon from '../../public/icons/nav/help.svg';
import DesignIcon from '../../public/icons/nav/designs.svg';
import VerifierIcon from '../../public/icons/nav/verifier.svg';
import SchemasIcon from '../../public/icons/nav/schemas.svg';
import EcosystemsIcon from '../../public/icons/nav/ecosystems.svg';

function trackDocumentation() {
  if (window._cio) {
    window._cio.track('open-documentation');
  }
}

function trackStatus() {
  if (window._cio) {
    window._cio.track('open-statuspage');
  }
}

function trackContact() {
  if (window._cio) {
    window._cio.track('open-contactus');
  }
}

const helpNavItems = {
  text: 'Help',
  href: '/help',
  icon: HelpIcon,
  session: true,
  defaultCollapsed: true,
  children: [
    {
      text: 'User Guide',
      href: 'https://docs.dock.io/dock-certs',
    },
    {
      text: 'Contact Us',
      href: 'mailto:contact@dock.io',
      track: trackContact,
    },
    {
      text: 'Dock System Status',
      href: 'https://dockstatus.io/',
      track: trackStatus,
    },
  ],
};

const navItems = [
  {
    text: 'Dashboard',
    href: '/dashboard',
    session: true,
    icon: HomeIcon,
  },
  {
    text: 'Credentials',
    href: '/credentials',
    session: true,
    icon: CredentialsIcon,
  },
  {
    text: 'Schemas',
    href: '/schemas',
    session: true,
    icon: SchemasIcon,
  },
  {
    text: 'Designer',
    href: '/designs',
    session: true,
    icon: DesignIcon,
  },
  {
    text: 'Verification',
    href: '/verification',
    session: true,
    icon: VerifierIcon,
  },
  {
    text: 'Organization Profiles',
    href: '/dids',
    session: true,
    icon: DIDIcon,
  },
  {
    text: 'Ecosystem',
    href: '/ecosystems',
    session: true,
    icon: EcosystemsIcon,
    new: true,
  },
  {
    text: 'Activity',
    href: '/activity',
    session: true,
    icon: ActivityIcon,
  },
  {
    text: 'Plan & Billing',
    href: '/plans',
    session: true,
    icon: PlanIcon,
  },
  {
    text: 'Documentation',
    href: 'https://docs.dock.io/developer-documentation/dock-api',
    session: false,
    icon: DeveloperIcon,
    track: trackDocumentation,
  },
  {
    text: 'Developer',
    href: '/developer',
    session: true,
    icon: DeveloperIcon,
    defaultCollapsed: true,
    children: [
      {
        text: 'API Keys',
        href: '/keys',
        session: true,
      },
      {
        text: 'Webhooks',
        href: '/webhooks',
        session: true,
      },
      {
        text: 'API Documentation',
        href: 'https://docs.dock.io/developer-documentation/dock-api',
        session: true,
        track: trackDocumentation,
      },
      {
        text: 'Wallet Documentation',
        href: 'https://docs.dock.io/dock-wallet',
        session: true,
        track: trackDocumentation,
      },
    ],
  },

  {
    text: 'Contact Us',
    href: 'mailto:contact@dock.io',
    track: trackContact,
    session: false,
    icon: ContactIcon,
  },
  {
    text: 'Dock System Status',
    href: 'https://dockstatus.io/',
    track: trackStatus,
    session: false,
    icon: HelpIcon,
  },
  helpNavItems,
];

const navItemsSuspended = [
  {
    text: 'Plan & Billing',
    href: '/plans',
    icon: PlanIcon,
  },
  helpNavItems,
];

function NavItem({ navItem, nested, onMail, session }) {
  const { asPath, push } = useRouter();
  const [open, setOpen] = useState(!navItem.defaultCollapsed);
  const isSelected = asPath === navItem.href || asPath.startsWith(navItem.href);
  const isExternal = navItem.href.startsWith('http');
  const hasChildren = navItem.children && navItem.children.length;

  const handleClick = (e) => {
    // If using ctrl key or external link, let browser handle navigation
    if (e.ctrlKey || isExternal) {
      return;
    }

    e.preventDefault();

    if (navItem.href.startsWith('mailto:')) {
      onMail();
      return;
    }

    if (hasChildren) {
      setOpen(!open);
    } else {
      push(navItem.href);
    }

    if (navItem.track) {
      navItem.track();
    }
  };

  const childSx = {
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px',
    textDecoration: 'none',
    borderRadius: '34px',
    width: '100%',
    height: '40px',
    textAlign: 'left',
    transition: 'all 0.2s ease-in-out',
    color: '#52525B',
    background: 'transparent',
    fontSize: '14px',
    '&:hover': {
      background: '#EFF4FF',
    },
    ...(isSelected
      ? {
          color: '#00359E',
          background: '#EFF4FF',
        }
      : undefined),
  };

  if (nested) {
    childSx.paddingLeft = '50px';
  }

  return (
    (navItem.session === undefined ||
      (navItem.session === true &&
        session &&
        (!navItem.featureFlagCheck || navItem.featureFlagCheck(session))) ||
      (navItem.session === false && !session)) && (
      <>
        <ListItem
          id={`${navItem.text.replace(' ', '-').toLowerCase()}-nav-btn`}
          data-intercom-target={`${navItem.text.replace(' ', '-').toLowerCase()}-nav-btn`}
          key={navItem.href}
          button
          component="a"
          onClick={handleClick}
          sx={childSx}
          selected={isSelected}
          href={!hasChildren ? navItem.href : undefined}
          target={isExternal ? '_blank' : undefined}>
          {navItem.icon && (
            <ListItemIcon sx={{ minWidth: '31px', color: isSelected ? '#004EEB' : '#3F3F46' }}>
              <navItem.icon />
            </ListItemIcon>
          )}
          <ListItemText
            disableTypography
            primary={navItem.text}
            sx={{
              fontSize: 14,
              fontWeight: 600,
            }}
          />
          {navItem.new && (
            <Chip
              label="New"
              color="primary"
              size="small"
              sx={{
                position: 'relative',
                top: '1px',
                fontSize: 9,
                height: 18,
                marginRight: 'auto',
              }}
            />
          )}
          {hasChildren && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {hasChildren && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {navItem.children.map((child) => (
                <NavItem
                  key={child.href}
                  navItem={child}
                  nested={true}
                  onMail={onMail}
                  session={session}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    )
  );
}

export default function NestedList({ onMail, session }) {
  const { t } = useTranslation('common');
  const shouldDisplaySpeakToAnExpertButton = session && session.user && !session.user.subscription;

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      sx={{
        width: '100%',
        maxWidth: 360,
        backgroundColor: 'transparent',
      }}>
      {session && session.user && session.user.status === 1 ? (
        <>
          {navItemsSuspended.map(
            (navItem) =>
              (!navItem.flag || navItem.flag(session && session.user)) && (
                <NavItem key={navItem.href} navItem={navItem} onMail={onMail} session={session} />
              )
          )}
        </>
      ) : (
        <>
          {shouldDisplaySpeakToAnExpertButton && (
            <ListItemButton
              component="a"
              sx={{
                fontSize: '14px',
                backgroundColor: '#F4F4F5',
                marginBottom: '24px',
                color: '#51525C',
                justifyContent: 'center',
                borderRadius: '10vw',
                height: '40px',
              }}
              href="https://meetings.hubspot.com/agne-caunt/talk-to-an-expert"
              target="_blank"
              rel="noreferrer">
              <ListItemIcon sx={{ minWidth: '31px' }}>
                <HeadsetMicIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t('speak-to-an-expert')}
                sx={{
                  fontWeight: 600,
                  flexGrow: 0,
                }}
              />
            </ListItemButton>
          )}
          {navItems.map(
            (navItem) =>
              (!navItem.flag || navItem.flag(session && session.user)) && (
                <NavItem key={navItem.href} navItem={navItem} onMail={onMail} session={session} />
              )
          )}
        </>
      )}
    </List>
  );
}
